import { Languages } from 'lucide-react';
import React, { useState, useEffect } from 'react';
import trFlag from "../assets/imgs/tr.svg";
import usFlag from "../assets/imgs/us.svg";
import deFlag from "../assets/imgs/de.svg";

const LanguageSwitcher = ({ classNames }) => {
  const changeLanguage = (lng) => {
    const googleTranslateElement = document.querySelector(".goog-te-combo");
    if (googleTranslateElement) {
      googleTranslateElement.value = lng;
      googleTranslateElement.dispatchEvent(new Event("change"));
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const googleTranslateElement = document.querySelector(".goog-te-combo");
      if (googleTranslateElement) {
        clearInterval(intervalId);
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={`flex items-center space-x-3 ${classNames}`}>
      <button onClick={() => changeLanguage("en")}>
        <img src={usFlag} alt="English" className="w-6 rounded-sm" />
      </button>
      <button onClick={() => changeLanguage("de")}>
        <img src={deFlag} alt="Deutsch" className="w-6 rounded-sm" />
      </button>
      <button onClick={() => changeLanguage("tr")}>
        <img src={trFlag} alt="Turkish" className="w-6 rounded-sm" />
      </button>
    </div>
  );
};

export default LanguageSwitcher;
