import React, { useRef } from 'react';
import img_bg from '../../assets/imgs/banner/4.jpg';
import Banner from '@/components/Banner';
import {
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Card, CardContent } from "@/components/ui/card";
import img_masa from '../../assets/imgs/unserservice/masa.jpg';
import img_bina from '../../assets/imgs/unserservice/bina.jpg';
import img_kar_kureme from '../../assets/imgs/unserservice/kar_kureme.jpg';
import img_masa_silme from '../../assets/imgs/unserservice/masa_silme.jpg';
import img_kapi_kolu from '../../assets/imgs/unserservice/kapi_kolu.jpg';
import { Building2, Drill, Paintbrush, PaintbrushVertical, ShieldPlus, Snowflake, Trash2 } from 'lucide-react';

const items = [
    {
      img: img_masa,
      title: 'Hausmeisterservice',
      description: 
      <div className="space-y-2">
          <h2 className="font-semibold text-gray-900 dark:text-white">Im gewünschten Turnus wird die Ordnung vom Objekt gewährleistet</h2>
          <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
              <li>Kehrdienst und Sauberhalten der Anlagen</li>
              <li>Beheben von kleineren Mängeln</li>
              <li>Betreuen der Müllanlagen mit Containerdienst</li>
              <li>Treppenhausreinigung</li>
          </ul>
      </div>,
      icon: <Paintbrush size={22} />
    },
    {
      img: img_bina,
      title: 'Glasreinigung',
      description: 
      <div className="space-y-2">
          <h2 className="font-semibold text-gray-900 dark:text-white">Saubere Fenster sind das Aushängeschild Ihres Betriebes oder Ihres Hauses</h2>
          <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
              <li>Reinigung Innen- und Außenfenster</li>
              <li>Reinigung Schaufenster</li>
              <li>Industrieverglasung</li>
              <li>Vordächer</li>
          </ul>
      </div>,
      icon: <PaintbrushVertical size={22} />
    },
    {
      img: img_kar_kureme,
      title: 'Winterdienst',
      description: 
      <div className="space-y-2">
          <h2 className="font-semibold text-gray-900 dark:text-white">Bei Schnee und Eisglätte: Wir sorgen für sichere Einfahrten und Zufahrtswege</h2>
          <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
              <li>Schneebeseitigung</li>
              <li>Schneeräumung</li>
              <li>Bekämpfung von Glätte und Eis</li>
              <li>Streudienst</li>
          </ul>
      </div>,
      icon: <Snowflake size={22} />
    },
    {
      img: img_masa_silme,
      title: 'Unterhaltsreinigung',
      description: 
      <div className="space-y-2">
          <h2 className="font-semibold text-gray-900 dark:text-white">Die Pflege und Werterhaltung der Innenbereiche Ihres Betriebes</h2>
          <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
              <li>Staubsaugen, Kehren, Feucht- und Nasswischen</li>
              <li>Abstauben und Abwischen von Oberflächen</li>
              <li>Reinigung der sanitären Anlagen</li>
              <li>Ein- und Ausräumen der Spülmaschine</li>
              <li>Büroreinigung</li>
              <li>Sanitär-/ Umkleidereinigung</li>
              <li>Treppenhausreinigung</li>
              <li>Küchen-/ Kantinenreinigung</li>
          </ul>
      </div>,
      icon: <Building2 size={22} />
    },
    {
      img: img_kapi_kolu,
      title: 'Bau(end)reinigung',
      description: 
      <div className="space-y-2">
          <h2 className="font-semibold text-gray-900 dark:text-white">Die Baureinigung schließt sich an Neubau- oder Umbaumaßnahmen an</h2>
          <ul className="max-w-md space-y-1 text-gray-500 list-disc list-inside dark:text-gray-400">
              <li>Baugrobreinigung und Baufeinreinigung</li>
              <li>Bauzwischenreinigung</li>
              <li>Bauendreinigung</li>
              <li>Innen- und Außenreinigung</li>
              <li>Grobreinigung</li>
              <li>Zwischenreinigung</li>
              <li>Feinreinigung</li>
              <li>Aussenreinigung</li>
          </ul>
      </div>,
      icon: <Trash2 size={22} />
    }
  ];
  

const bannerItems = [
  {
    img: img_bg,
    title: <div className="truncate">Dienstleistungen</div>,
    description: "Ob Gebäudemanagement, Glasreinigung, Winterdienste oder spezialisierte Reinigungsarbeiten - wir bieten Ihnen flexible und zuverlässige Lösungen.",  
    classname: 'pt-20'
  }
]

const Dienstleistungen = () => {
  return (
    <div>
      <Banner
        containerClassname="pointer-events-none select-none"
        contentClassname="h-[50vh]"
        bannerItems={bannerItems}
        breadcrumbListElement={
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href="/unserservice">Unser Service</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Dienstleistungen</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        }
      />
      

      <div className="bg-white w-full pb-7 pt-10 text-slate-900">
        <div className="w-full text-4xl font-semibold text-slate-900 pb-4">
          <div className="relative text-center mb-5">
            <div className="absolute inset-0 flex items-center justify-center opacity-20">
              <span className="text-5xl lg:text-6xl font-bold text-gray-300">
                Unsere Dienstleistungen
              </span>
            </div>
            <div className="relative">
              <span className="text-4xl font-bold text-blue-900">
                Unsere Dienstleistungen
              </span>
            </div>
          </div>
        </div>
        <div className="mx-auto w-5/6 lg:w-4/6">
            Unsere erfahrenen Fachkräfte kümmern sich umfassend um die Sauberkeit und Sicherheit Ihrer Immobilien. Kontaktieren Sie uns und erleben Sie Service, der genau auf Ihre Anforderungen zugeschnitten ist.
        </div>
      </div>

      

      <div className="bg-white w-full py-10 pt-5 text-slate-100 border-b-4 border-dashed border-slate-300">
        <div className="mx-auto w-full flex flex-wrap justify-center px-5 md:px-10 gap-5">
          {items.map((item, index) => (
            <Card key={index} className="bg-slate-100 w-full md:basis-1/3 xl:basis-1/4 shadow-lg rounded-lg transition-all overflow-hidden">
              <div className="flex-grow flex flex-col">
                <CardContent className="aspect-square items-center justify-center rounded-lg rounded-t-3xl bg-slate-100 p-0 pb-0.5 flex-grow flex flex-col">
                  <div className="w-full rounded-t-lg bg-black">
                    <img src={item.img} className="w-full rounded-t-lg select-none opacity-80" />
                  </div>
                  <div className='text-center p-3 pb-4 px-5 m-4 rounded rounded-t-2xl bg-white shadow-lg relative z-20 flex-grow flex flex-col justify-between' style={{ marginTop: '-2rem' }}>
                    <div className="text-xl font-semibold">
                      {item.title}
                    </div>
                    <div className="relative flex items-center">
                      <div className="flex-grow border-t border-blue-100"></div>
                      <span className="flex-shrink mx-3 my-2 text-gray-400 tracking-widest">
                        {item.icon}
                      </span>
                      <div className="flex-grow border-t border-blue-100"></div>
                    </div>
                    <div className="w-full text-left text-sm text-slate-600">
                      {item.description}
                    </div>
                    
                  </div>
                </CardContent>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Dienstleistungen;
