import React, { useState } from "react";
import img_plane from "../assets/imgs/unserservice/plane.jpg";
import Banner from "@/components/Banner";
import {
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Mail, MessagesSquare, PhoneCall, SendHorizonal, Loader } from "lucide-react"; // Import Loader icon
import emailjs from 'emailjs-com';
import { useToast } from "@/components/ui/use-toast";

// Load environment variables
const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
const USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;

// Sanitize input function
const sanitizeInput = (input) => {
  const map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#x27;',
    "/": '&#x2F;',
  };
  const reg = /[&<>"'/]/ig;
  return input.replace(reg, (match) => (map[match]));
};

const bannerItems = [
  {
    img: img_plane,
    title: "Kontakt",
    classname: "pt-20",
  },
];

const Kontakt = () => {
  const { toast } = useToast();
  const [formData, setFormData] = useState({
    firmname: '',
    name: '',
    email: '',
    telefon: '',
    betreff: '',
    nachricht: '',
  });
  const [loading, setLoading] = useState(false); // State for loading

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: sanitizeInput(value), // Sanitize the input
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast({
        variant: "destructive",
        title: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      });
      setLoading(false); // Stop loading
      return;
    }

    if (!formData.name || !formData.email || !formData.telefon || !formData.betreff || !formData.nachricht) {
      toast({
        variant: "destructive",
        title: "Bitte stellen Sie sicher, dass alle Felder korrekt ausgefüllt sind oder versuchen Sie es später erneut.",
      });
      setLoading(false); // Stop loading
      return;
    }

    emailjs.send(SERVICE_ID, TEMPLATE_ID, formData, USER_ID)
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        toast({
          title: "E-Mail erfolgreich gesendet!",
          description: "Vielen Dank für Ihre Nachricht. Wir werden uns so schnell wie möglich bei Ihnen melden.",
        });
        setLoading(false); // Stop loading
      }, (err) => {
        console.log('FAILED...', err);
        if (err.text.includes('Domain not found')) {
          toast({
            variant: "destructive",
            title: "Ihre E-Mail-Adresse wurde nicht gefunden.",
            description: "Bitte stellen Sie sicher, dass Sie Ihre E-Mail-Adresse korrekt eingegeben haben.",
          });
        } else {
          toast({
            variant: "destructive",
            title: "Fehler beim Senden der E-Mail.",
            description: "Bitte stellen Sie sicher, dass alle Felder korrekt ausgefüllt sind oder versuchen Sie es später erneut.",
          });
        }
        setLoading(false); // Stop loading
      });
  };

  return (
    <div>
      <Banner
        containerClassname="pointer-events-none select-none"
        contentClassname="h-[40vh]"
        bannerItems={bannerItems}
        breadcrumbListElement={
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Kontakt</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        }
      />
      <div className="border-b-4 border-dashed border-red-500 w-full">
        <div className="space-y-4 grid grid-cols-1 xl:grid-cols-2 gap-8 gap-y-5 mx-auto p-4 pb-10 w-full lg:px-0 lg:w-4/5">
          <div className="w-full border-b flex pb-3 lg:pb-0 items-center text-2xl">
            Kontakformular
          </div>
          <div className="w-full hidden lg:block border-b pb-3 text-2xl flex items-center">
            SO ERREICHEN SIE UNS
          </div>
          <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-5 md:px-3">
            <Input type="text" name="firmname" placeholder="Firmenname" value={formData.firmname} onChange={handleChange} />
            <Input type="text" name="name" placeholder="Name*" value={formData.name} onChange={handleChange} required />
            <Input type="email" name="email" placeholder="E-Mail*" value={formData.email} onChange={handleChange} required />
            <Input type="number" name="telefon" placeholder="Telefon*" value={formData.telefon} onChange={handleChange} required />
            <Input type="text" name="betreff" placeholder="Betreff*" className="col-span-2" value={formData.betreff} onChange={handleChange} required />
            <Textarea name="nachricht" placeholder="Ihre Nachricht*" className="col-span-2" value={formData.nachricht} onChange={handleChange} required />
            <Button type="submit" variant="destructive" disabled={loading} className="col-span-2 bg-yellow-hr transition-all space-x-2 flex items-center">
              {loading ? <Loader className="animate-spin" size={18} /> : <div className="flex items-center space-x-2"><div>SENDEN</div>
              <SendHorizonal size={18} /></div>}
            </Button>
          </form>
          <div className="w-full lg:hidden border-b pb-3 text-2xl flex items-center">
            SO ERREICHEN SIE UNS
          </div>
          <div className="md:px-3">
            <div className="border rounded-md w-full p-2 py-3 grid grid-cols-4 gap-1 h-fit">
              <div className="col-span-1 flex items-center">
                <MessagesSquare size={54} className="mx-auto" strokeWidth={1.5} />
              </div>
              <div className="col-span-3 px-1 md:px-3 text-sm flex items-center">
                <div className="space-y-2">
                  <div className="flex items-center space-x-2">
                    <PhoneCall size={18} />
                    <div>0621 437 576 07</div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Mail size={18} />
                    <div>info@hr-personal24.de</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full border-y-4 border-dashed border-violet-100">
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2591.5509323807987!2d8.4580772!3d49.49298919999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4797cc39ed8f90c3%3A0x3a5918717d61f813!2sKirchenstra%C3%9Fe+12%2C+68159+Mannheim%2C+Almanya!5e0!3m2!1str!2sao!4v1720287873561!5m2!1str!2sao"
          className="w-full h-[450px] !border-0 !outline-none !ring-0"
          allowfullscreen=""
          loading="lazy"
          tabIndex="0"
          aria-hidden="false"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Kontakt;
