import React, { useRef } from 'react';
import img_bg from '../../assets/imgs/home/2.jpg';
import img_1 from '../../assets/imgs/unserservice/1.jpg';
import img_2 from '../../assets/imgs/unserservice/2.jpg';
import img_bghand from '../../assets/imgs/unserservice/bghand.jpeg';
import Banner from '@/components/Banner';
import {
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Separator } from "@/components/ui/separator"
import { Card, CardContent } from "@/components/ui/card";
import img_career_Staplerfahrer from '../../assets/imgs/unserservice/Staplerfahrer.jpeg';
import img_career_Schweiser from '../../assets/imgs/unserservice/Schweißer.jpg';
import img_career_Reinigungsfachkraft from '../../assets/imgs/unserservice/Reinigungsfachkraft.jpg';
import img_career_Maurer from '../../assets/imgs/unserservice/Maurer.jpg';
import img_career_Maschinenbediener from '../../assets/imgs/unserservice/Maschinenbediener.jpg';
import img_career_LKW_Fahrer from '../../assets/imgs/unserservice/LKW Fahrer.jpg';
import img_career_Elektriker from '../../assets/imgs/unserservice/Elektriker.jpg';
import img_career_Disponent from '../../assets/imgs/unserservice/Disponent.jpg';
import img_career_Produktionshelfer from '../../assets/imgs/unserservice/Produktionshelfer.jpg';

import Autoplay from "embla-carousel-autoplay";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { BatteryCharging, HandHelping, ThumbsUp, UserRound } from 'lucide-react';
import { Link } from 'react-router-dom';

const carouselCareerItems = [
  {
    img: img_career_Staplerfahrer,
    text: 'Staplerfahrer',
  },
  {
    img: img_career_Schweiser,
    text: 'Schweißer',
  },
  {
    img: img_career_Reinigungsfachkraft,
    text: 'Reinigungsfachkraft',
  },
  {
    img: img_career_Maurer,
    text: 'Maurer',
  },
  {
    img: img_career_Maschinenbediener,
    text: 'Maschinenbediener',
  },
  {
    img: img_career_LKW_Fahrer,
    text: 'LKW Fahrer',
  },
  {
    img: img_career_Elektriker,
    text: 'Elektriker',
  },
  {
    img: img_career_Disponent,
    text: 'Disponent',
  },
  {
    img: img_career_Produktionshelfer,
    text: 'Produktionshelfer',
  },
];

const bannerItems = [
  {
    img: img_bghand,
    title: "Personalvermittlung",
   
    classname: 'pt-24'
  }
]

const Personalvermittlung = () => {
  const pluginCareer = useRef(
    Autoplay({ delay: 3000, stopOnInteraction: false })
  )
  return (
    <div>
      <Banner
        containerClassname="pointer-events-none select-none"
        contentClassname="h-[40vh]"
        bannerItems={bannerItems}
        breadcrumbListElement={
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href="/unserservice">Unser Service</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Personalvermittlung</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        }
      />
      

      <div className="bg-white w-full py-10 text-slate-900">
        <div className="mx-auto w-5/6 lg:w-4/6 items-center">
          <div className="grid grid-cols-2 gap-5">
            <div className="text-base text-center">
              <div>Qualifizierte Mitarbeiter finden Sie durch unser Engagement.</div>
              <div>Natürlich passend für Ihre Bedürfnisse.</div>
              <div>In Zeitarbeit, Festanstellung oder auf Projektbasis.</div>
              <div>Ihre Zeit ist kostbar. Deshalb unterstützen wir Sie bei Ihrer Mitarbeitersuche und dem Einstellungsprozess.</div>
              <div>Unser Personal ist leistungsfähig, sicherheitsbewusst, verantwortungsbewusst.</div>
              <div>Vorausschauende Planung, unternehmerische Verantwortung, und präzise Kenntnis des Arbeitsmarktes machen uns zu einem zuverlässigen und kompetenten Partner für Ihren Personalbedarf.</div>
            </div>
            <img src={img_bg} alt="Aviation" className='w-2/3 rounded-xl shadow-xl mx-auto' />
          </div>
          <Separator className="w-full my-5" />
          <div className="text-lg grid grid-cols-1 md:grid-cols-3 space-y-7 md:space-y-0 md:space-x-10">
            <div className='flex md:justify-end space-x-3 font-semibold items-center transition-all'>
              <div className='rounded-full bg-yellow-300 p-4'>
                <UserRound size={22} />
              </div>
              <div>INDIVIDUELL</div>
            </div>
            <div className='flex md:justify-center space-x-3 font-semibold items-center transition-all'>
              <div className='rounded-full bg-yellow-300 p-4'>
                <BatteryCharging size={22} />
              </div>
              <div>SCHNELL</div>
            </div>
            <div className='flex md:justify-start space-x-3 font-semibold items-center transition-all'>
              <div className='rounded-full bg-yellow-300 p-4'>
                <ThumbsUp size={22} />
              </div>
              <div>UNKOMPLIZIERT</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Personalvermittlung
