import React, { useEffect, useState } from "react";
import img_bg from "../../assets/imgs/home/4.jpg";
import Banner from "@/components/Banner";
import {
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import img_plane from "../../assets/imgs/unserservice/plane.jpg";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const bannerItems = [
  {
    img: img_bg,
    title: "Datenschutz.",
    classname: "pt-20",
  },
];

const Datenschutz = () => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'accepted') {
      setShowContent(true);
    }
  }, []);

  return (
    <div>
      {showContent && (
        <Banner
          containerClassname="pointer-events-none select-none"
          contentClassname="h-[40vh]"
          bannerItems={bannerItems}
          breadcrumbListElement={
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Home</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Legal</BreadcrumbLink>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>Datenschutz</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          }
        />
      )}
      <div className={`space-y-4 grid mx-auto p-4 w-full md:px-0 md:w-2/3 ${showContent ? '' : 'pt-20'}`}>
        <div className="text-2xl mx-auto font-bold mb-2">
          Datenschutz
        </div>
        <div className="text-lg font-bold mb-2">
          Hinweise zur Datenverarbeitung
        </div>
        <div className="mb-2">
          <span className="font-bold">
            Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
          </span>
        </div>
        <div className="mb-2">
          Diese Datenschutz-Information gilt für die Datenverarbeitung durch:
        </div>
        <div className="mb-2">
          <span className="font-bold">Verantwortlicher:</span>
          <br />
          Ruzica Stevic
          <br />
          HR Personalmanagement GmbH
          <br />
          Kirchenstrasse 12
          <br />
          68159 Mannheim
          <br />
          Deutschland
          <br />
          Email:{" "}
          <a href="mailto:info@hr-personal24.de" className="text-blue-500">
            info@hr-personal24.de
          </a>
        </div>
        <div className="mb-4">
          <span className="font-bold">
            1. Erhebung und Speicherung personenbezogener Daten sowie Art und
            Zweck von deren Verwendung beim Besuch der Webseite
          </span>
          <br />
          Beim Aufrufen unserer Website{" "}
          <a href="https://hr-personal24.de" className="text-blue-500">
            https://hr-personal24.de
          </a>{" "}
          werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser
          automatisch Informationen an den Server unserer Website gesendet.
          Diese Informationen werden temporär in einem Logfile gespeichert.
          Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur
          automatisierten Löschung gespeichert:
          <ul className="list-disc list-inside ml-4">
            <li>IP-Adresse des anfragenden Rechners,</li>
            <li>Datum und Uhrzeit des Zugriffs,</li>
            <li>Name und URL der abgerufenen Datei,</li>
            <li>
              Website, von welcher aus dem Zugriff erfolgt (Referrer-URL),
            </li>
            <li>
              verwendeter Browser und ggf. das Betriebssystem und der Gerätetyp
              Ihres Rechners sowie der Name Ihres Access-Providers.
            </li>
          </ul>
        </div>
        <div className="mb-4">
          Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:
          <ul className="list-disc list-inside ml-4">
            <li>
              Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,
            </li>
            <li>Gewährleistung einer komfortablen Nutzung unserer Website,</li>
            <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
            <li>zu weiteren administrativen Zwecken.</li>
          </ul>
        </div>
        <div className="mb-4">
          Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1
          lit. f DSGVO. Unser berechtigtes Interesse folgt aus oben
          aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir
          die erhobenen Daten zu dem Zweck, Rückschlüsse auf Ihre Person zu
          ziehen.
        </div>
        <div className="mb-4">
          <span className="font-bold">2. Weitergabe von Daten</span>
          <br />
          Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als
          den im Folgenden aufgeführten Zwecken findet nicht statt.
          <ul className="list-disc list-inside ml-4">
            <li>
              Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche
              Einwilligung dazu erteilt haben,
            </li>
            <li>
              die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur
              Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen
              erforderlich ist und kein
            </li>
          </ul>
        </div>
        <div className="mb-4">
          Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges
          Interesse an der Nichtweitergabe Ihrer Daten haben,
          <ul className="list-disc list-inside ml-4">
            <li>
              für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit.
              c DSGVO eine gesetzliche Verpflichtung besteht, sowie
            </li>
            <li>
              dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO
              für die Abwicklung von Vertragsverhältnissen mit Ihnen
              erforderlich ist.
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <span className="font-bold">3. Cookies</span> Wir setzen auf unserer
          Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr
          Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop,
          Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere Seite
          besuchen. Cookies richten auf Ihrem Endgerät keinen Schaden an,
          enthalten keine Viren, Trojaner oder sonstige Schadsoftware. Im Cookie
          werden Informationen abgelegt, die sich jeweils im Zusammenhang mit
          dem spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch
          nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identität
          erhalten. Der Einsatz von Cookies dient dazu, die Nutzung unseres
          Angebots für Sie angenehmer zu gestalten. Wir setzen technische
          Cookies ein. Diese Art von Cookies wird eingesetzt, um den
          reibungslosen Betrieb der Website zu garantieren. Diese
          Session-Cookies setzen wir ein, um zu erkennen, dass Sie einzelne
          Seiten unserer Website bereits besucht haben. Diese werden nach
          Verlassen unserer Seite automatisch gelöscht. Die durch Cookies
          verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer
          berechtigten Interessen sowie der Dritter nach Art. 6 Abs. 1 S. 1 lit.
          f DSGVO erforderlich. Die meisten Browser akzeptieren Cookies
          automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass
          keine Cookies auf Ihrem Computer gespeichert werden oder stets ein
          Hinweis erscheint, bevor ein neues Cookie angelegt wird. Die
          vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass
          Sie nicht alle Funktionen unserer Website nutzen können.
        </div>
        <div className="mb-4">
          <span className="font-bold">4. Analyse-Tools</span> Die im Folgenden
          aufgeführte und von uns eingesetzte Tracking-Maßnahme wird auf
          Grundlage des Art. 6 Abs. 1 S. 1 lit. f DSGVO durchgeführt. Mit der
          zum Einsatz kommenden Tracking-Maßnahme wollen wir eine
          bedarfsgerechte Gestaltung und die fortlaufende Optimierung unserer
          Webseite sicherstellen. Zum anderen setzen wir die Tracking-Maßnahme
          ein, um die Nutzung unserer Webseite statistisch zu erfassen und zum
          Zwecke der Optimierung unseres Angebotes für Sie auszuwerten. Diese
          Interessen sind als berechtigt im Sinne der vorgenannten Vorschrift
          anzusehen. Die jeweiligen Datenverarbeitungszwecke und Datenkategorien
          sind aus dem entsprechenden Tracking-Tools zu entnehmen.
        </div>
        <div className="mb-4">
          <span className="font-bold">5. Google Webanalytics</span>
          <ul className="list-disc list-inside ml-4">
            <li>Referrer (zuvor besuchte Webseite)</li>
            <li>Angeforderte Webseite oder Daten</li>
            <li>Browsertyp und Browserversion</li>
            <li>Verwendetes Betriebssystem</li>
            <li>Verwendeter Gerätetyp</li>
            <li>Uhrzeit des Zugriffs</li>
            <li>
              IP-Adresse in anonymisierter Form (wird nur zur Feststellung des
              Orts des Zugriffs verwendet)
            </li>
            <li>
              Die Google Datenschutzerklärung können Sie{" "}
              <a
                href="https://policies.google.com/privacy"
                className="text-blue-500"
              >
                hier
              </a>{" "}
              nachlesen
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <span className="font-bold">6. Sicherheitsplugins</span> Auf der
          Webseite{" "}
          <a href="https://hr-personal24.de" className="text-blue-500">
            https://hr-personal24.de
          </a>{" "}
          kommt das Plugin „Wordfence Firewall“ zum Einsatz. Dies dient dem
          Schutz vor Angriffen aus dem Internet, Viren, Brute Force Angriffen
          und zerstörenden Code. Es wird nur die Art und Anzahl der Angriffe von
          dem Plugin protokolliert. Bei fehlgeschlagenen, sowie erfolgreichen
          Login-Versuchen wird zusätzlich die IP-Adresse des jeweiligen
          Benutzers hinzugefügt.
          <div className="mt-2">
            Die IP-Adresse eines Webseiten-Besuchers ist von einer Speicherung
            ausgeschlossen!
          </div>
          <div className="mt-2">
            IP-Adressen werden zur Identifikation benötigt, um zukünftige
            Angriffe jeder Art zu blockieren und rechtliche Maßnahmen geltend zu
            machen. Aus diesem Grund kann die IP-Adresse bei einer Anmeldung auf
            der Webseite nicht verschleiert werden. Die Datenschutzrichtlinie
            des Herstellers, können Sie auf der Webseite,{" "}
            <a
              href="https://www.defiant.com/terms-of-service/"
              className="text-blue-500"
            >
              Defiant Inc. Wordfence
            </a>
            , COO for Defiant Inc., 1700 Westlake Ave N Ste 200, Seattle, WA
            98109, in englischer Sprache nachlesen.
          </div>
        </div>
        <div>
          <span className="font-bold">7. Betroffenenrechte</span>
          <ul className="list-disc list-inside ml-4">
            <li>
              gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten
              personenbezogenen Daten zu verlangen. Insbesondere können Sie
              Auskunft über die Verarbeitungszwecke, die Kategorie der
              personenbezogenen Daten, die Kategorien von Empfängern, gegenüber
              denen Ihre Daten offengelegt wurden oder werden, die geplante
              Speicherdauer, das Bestehen eines Rechts auf Berichtigung,
              Löschung, Einschränkung der Verarbeitung oder Widerspruch, das
              Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern
              diese nicht bei mir erhoben wurden, sowie über das Bestehen einer
              automatisierten Entscheidungsfindung einschließlich Profilierung
              und ggf. aussagekräftigen Informationen zu deren Einzelheiten
              verlangen;
            </li>
            <li>
              gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder
              Vervollständigung Ihrer bei uns gespeicherten personenbezogenen
              Daten zu verlangen;
            </li>
            <li>
              gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten
              personenbezogenen Daten zu verlangen, soweit nicht die
              Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung
              und Information, zur Erfüllung einer rechtlichen Verpflichtung,
              aus Gründen des öffentlichen Interesses oder zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist;
            </li>
            <li>
              gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen, soweit die Richtigkeit der
              Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig
              ist, Sie aber deren Löschung ablehnen und wir die Daten nicht mehr
              benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder
              Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß Art. 21
              DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
            </li>
            <li>
              gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
              bereitgestellt haben, in einem strukturierten, gängigen und
              maschinenlesbaren Format zu erhalten oder die Übermittlung an
              einen anderen Verantwortlichen zu verlangen;
            </li>
            <li>
              gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung
              jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass
              wir die Datenverarbeitung, die auf dieser Einwilligung beruhte,
              für die Zukunft nicht mehr fortführen dürfen und
            </li>
            <li>
              gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren.
              In der Regel können Sie sich hierfür an die Aufsichtsbehörde Ihres
              üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres
              Kanzleisitzes wenden.
            </li>
          </ul>
        </div>
        <div className="mb-4">
          <span className="font-bold">8. Widerspruchsrecht</span> Sofern Ihre
          personenbezogenen Daten auf Grundlage von berechtigten Interessen
          gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO verarbeitet werden, haben Sie
          das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung
          Ihrer personenbezogenen Daten einzulegen, soweit dafür Gründe
          vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich
          der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben
          Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen
          Situation von mir umgesetzt wird.
          <div className="mt-2">
            Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
            machen, genügt eine E-Mail an{" "}
            <a href="mailto:info@hr-personal24.de" className="text-blue-500">
              info@hr-personal24.de
            </a>
          </div>
        </div>
        <div className="mb-4">
          <span className="font-bold">9. Datensicherheit</span> Wir verwenden
          innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure
          Socket Layer) in Verbindung mit der jeweils höchsten
          Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. In der
          Regel handelt es sich dabei um eine 256 Bit Verschlüsselung. Falls Ihr
          Browser keine 256-Bit Verschlüsselung unterstützt, greifen wir
          stattdessen auf 128-Bit v3 Technologie zurück. Ob eine einzelne Seite
          unseres Internetauftrittes verschlüsselt übertragen wird, erkennen Sie
          an der geschlossenen Darstellung des grünen Schloss-Symbols neben der
          Adresszeile Ihres Browsers.
          <div className="mt-2">
            Wir bedienen uns im Übrigen geeigneter technischer und
            organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen
            zufällige oder vorsätzliche Manipulationen, teilweisen oder
            vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff
            Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend
            der technologischen Entwicklung fortlaufend verbessert.
          </div>
        </div>
        <div className="mb-4">
          <span className="font-bold">
            10. Aktualität und Änderung dieser Datenschutzerklärung
          </span>{" "}
          Diese Datenschutzerklärung ist aktuell gültig und hat den Stand
          Dezember 2022.
          <div className="mt-2">
            Durch die Weiterentwicklung unserer Website und Angebote oder auf
            Grund geänderter gesetzlicher, beziehungsweise behördlicher Vorgaben
            kann es notwendig werden, diese Datenschutzerklärung zu ändern. Die
            jeweils aktuelle Datenschutzerklärung kann jederzeit von Ihnen
            eingesehen oder heruntergeladen werden.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Datenschutz;
