import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from '@/components/ui/button';
import { CircleDashed, CircleDotDashed, Dot } from 'lucide-react';
import LanguageSwitcher from './LanguageSwitcher';

const CookieConsentDialog = ({ setTemporaryConsent }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent !== 'accepted') {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setTemporaryConsent(true);
    setOpen(false);
  };

  const handleDecline = () => {
    setTemporaryConsent(true);
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={() => {}}>
      <DialogContent showCloseButton={false} onOpenAutoFocus={(event) => event.preventDefault()} className="p-5 md:p-6 !rounded-lg">
        <DialogHeader>
          <DialogTitle className="flex items-center justify-between">
            <div className='text-xl'>Datenschutz</div>
            <LanguageSwitcher />
          </DialogTitle>
          <DialogDescription className="md:px-2 text-center md:text-start text-xs md:text-sm">
            <p className='my-2'>Wir verwenden Cookies und ähnliche Technologien auf unserer Website und verarbeiten personenbezogene Daten über Sie. Wir teilen diese Daten auch mit anderen Services. Die Datenverarbeitung kann mit Ihrer Einwilligung oder auf Basis eines berechtigten Interesses erfolgen, dem Sie in den individuellen Privatsphäre-Einstellungen widersprechen können. Sie haben das Recht, nur in essenzielle Services einzuwilligen und Ihre Einwilligung in der Datenschutzerklärung zu einem späteren Zeitpunkt zu ändern oder zu widerrufen.</p>
            <p>Wenn du alle Services akzeptierst, erlaubst du, dass Google Maps, Giphy - Animierte Gifs, YouTube, Kommentare, Emojis, Vimeo, Google Trends, Google Fonts und Google Analytics geladen werden. Diese sind nach ihrem Zweck in Gruppen Funktional und Statistik unterteilt (Zugehörigkeit durch hochgestellte Zahlen gekennzeichnet).</p>
            <a href="/legal/datenschutz" className="underline" target="_blank">privacy policy</a>
          </DialogDescription>
        </DialogHeader>
        <div className="flex space-x-2 mt-4 px-2 mx-auto">
          <Button onClick={handleAccept}>Alle akzeptieren</Button>
          <Button variant="outline" onClick={handleDecline}>Weiter ohne Einwilligung</Button>
        </div>
        <DialogFooter>
          <div className="mx-auto flex items-center space-x-2 text-violet-500 text-sm">
            <a href="/legal/datenschutz" className="underline" target="_blank">Datenschutzerklärung</a>
            <Dot size={20} className='text-violet-400' />
            <a href="/legal/impressum" className="underline" target="_blank">Impressum</a>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CookieConsentDialog;
