import React, { useRef } from 'react';
import img_bg from '../assets/imgs/banner/1.jpg';
import img_1 from '../assets/imgs/home/1.jpg';
import img_2 from '../assets/imgs/home/2.jpg';
import img_3 from '../assets/imgs/home/3.jpg';
import img_4 from '../assets/imgs/home/4.jpg';
import img_5 from '../assets/imgs/home/5.jpg';
import img_partner_1 from '../assets/imgs/home/partners/1.jpg';
import img_partner_2 from '../assets/imgs/home/partners/2.jpg';
import img_partner_3 from '../assets/imgs/home/partners/3.jpg';
import img_partner_4 from '../assets/imgs/home/partners/4.jpg';
import img_partner_5 from '../assets/imgs/home/partners/5.jpg';
import img_partner_6 from '../assets/imgs/home/partners/6.jpg';
import img_partner_7 from '../assets/imgs/home/partners/7.jpg';
import Autoplay from "embla-carousel-autoplay";
import { Card, CardContent } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { BadgeCheck, BatteryCharging, BicepsFlexed, ChevronRight, ChevronsRight, ChevronsUp, CircleArrowRight, Gauge, GripHorizontal, HandHelping, Handshake, Network, PersonStanding, ShieldCheck, ShieldPlus, Waypoints } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import Banner from '@/components/Banner';

const carouselCareerItems = [
  {
    img: img_1,
    title: 'Arbeitnehmerüberlassung',
    description: 'Wir sind Ihr kompetenter Partner für Ihren Personalbedarf.',
    icon: <HandHelping size={20} />,
    url: '/unserservice/arbeitnehmerüberlassung',
    classname: 'pt-20'
  },
  {
    img: img_2,
    title: 'Personalvermittlung',
    description: 'Qualifiziertes Personal finden Sie in kürzester Zeit durch unser Engagement.',
    icon: <ChevronsUp size={20} />,
    url: '/unserservice/personalvermittlung'
  },
  {
    img: img_3,
    title: 'Dienstleistungen',
    description: 'Wir bieten umfassende Dienstleistungen von der Produktion bis zur Schweißtechnik an',
    icon: <GripHorizontal size={20} />,
    url: '/unserservice/dienstleistungen'
  },
  {
    img: img_4,
    title: 'Aviation',
    description: 'Mit unseren umfassenden Luftfahrtdiensten setzen wir neue Standards in Betrieb und Wartung für höchste Sicherheit...',
    icon: <ShieldCheck size={20} />,
    url: '/unserservice/aviation'
  }
];


const uberUnsItems = [
  {
    title: 'Kompetenz',
    description: 'Mit unseren umfassenden Luftfahrtdiensten setzen wir neue Standards in Betrieb und Wartung für höchste Sicherheit und Effizienz.',
    icon: <ShieldPlus size={50} />
  },
  {
    title: 'Zuverlässigkeit',
    description: 'Sie können sich auf uns verlassen. Wir liefern stets pünktlich und halten unsere Versprechen. Qualität ist unser Versprechen.',
    icon: <BicepsFlexed size={50} />
  },
  {
    title: 'Schnelligkeit',
    description: 'Schnelle Reaktion auf Kundenanfragen ist Standard. Wir optimieren laufend Prozesse für zeitnahe Lösungen.',
    icon: <Gauge size={50} />
  },
  {
    title: 'Netzwerk',
    description: 'Unser umfangreiches Netzwerk an Branchenpartnern ermöglicht vielfältige Lösungen. Zusammenarbeit ist der Schlüssel zum Erfolg.',
    icon: <Waypoints size={50} />
  },
  {
    title: 'Maßgeschneidert',
    description: 'Jede Lösung wird speziell auf Ihre Bedürfnisse zugeschnitten. Wir verstehen und schätzen die Einzigartigkeit jedes Kunden und passen unsere Dienstleistungen an.',
    icon: <PersonStanding size={50} />
  },
  {
    title: 'Innovation',
    description: 'Innovation treibt uns voran. Wir entwickeln kontinuierlich neue Strategien, um den Marktbedürfnissen voraus zu sein und effektive Lösungen zu bieten.',
    icon: <BatteryCharging size={50} />
  }
];

const Home = () => {
  const pluginCareer = useRef(
    Autoplay({ delay: 5000, stopOnInteraction: true })
  );
  const pluginPartner = useRef(
    Autoplay({ delay: 2500, stopOnInteraction: false })
  );

  return (
    <div>
      <Banner contentClassname="h-[60vh] md:h-[85vh]" />
      
      <div className="bg-white w-full py-12">
        <div className="mx-auto w-5/6 lg:w-4/6 space-y-8">
          <div className="w-full text-4xl font-semibold text-slate-900">
            <div class="relative text-center mb-5">
              <div class="absolute inset-0 flex items-center justify-center opacity-20">
                <span class="text-5xl lg:text-6xl font-bold text-gray-300">
                  Klare Wegweiser für Ihre <span className='text-yellow-hr'>Karriere</span>
                </span>
              </div>
              <div class="relative">
                <span class="text-4xl font-bold text-blue-900">
                  Klare Wegweiser für Ihre <span className='text-yellow-hr'>Karriere</span>
                </span>
              </div>
            </div>
          </div>
          <div className="w-full text-center text-slate-600">
            <span className="">Professionell</span>, authentisch und seriös. 
            Suchen Sie eine <span className="">neue Herausforderung</span> oder möchten Sie einfach den <span className="">Arbeitsmarkt-Dschungel</span> durchblicken? 
            Der Arbeitsmarkt ist oft nicht leicht zu durchblicken. Wir unterstützen Sie dabei.
          </div>

                  
          <Carousel className="w-full" plugins={[pluginCareer.current]} onMouseEnter={pluginCareer.current.stop} onMouseLeave={pluginCareer.current.reset}>
            <CarouselContent className="-ml-1">
              {carouselCareerItems.map((item, index) => (
                <CarouselItem key={index} className="pl-1 md:basis-1/2 xl:basis-1/3">
                  <div className="p-1">
                    <Link to={item.url}>
                      <Card>
                        <CardContent className="aspect-square items-center justify-center bg-gradient-to-r from-slate-50 via-blue-100 to-slate-50 p-0 pb-0.5">
                          <div className="w-full rounded-t-lg bg-black">
                            <img src={item.img} className="w-full rounded-t-lg select-none opacity-80" />
                          </div>
                          <div className='text-center p-3 px-5 m-4 rounded rounded-t-xl bg-white shadow-md relative z-20 h-[150px] sm:h-[170px] space-y-2' style={{ marginTop: '-2rem' }}>
                            <div className="text-md md:text-xl font-semibold">
                              {item.title}
                            </div>
                            <div className="relative flex items-center">
                              <div className="flex-grow border-t border-blue-100"></div>
                              <span className="flex-shrink mx-3 text-gray-400 tracking-widest">
                                {item.icon}
                              </span>
                              <div className="flex-grow border-t border-blue-100"></div>
                            </div>
                            <div className="w-full text-center text-xs md:text-sm text-slate-600">
                              {item.description}
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Link>
                  </div>
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious className="ms-8 md:ms-0" />
            <CarouselNext className="me-8 md:me-0" />
          </Carousel>
        </div>
      </div>

      <div className="bg-slate-900 w-full py-12 text-slate-100 border-b-4 border-dashed border-slate-700">
        <div className="mx-auto w-full px-3 sm:px-0 sm:w-5/6 lg:w-4/6 space-y-8">
          <div className="w-full text-4xl font-semibold text-slate-900">
            <div class="relative text-center mb-5">
              <div class="absolute inset-0 flex items-center justify-center opacity-10">
                <span class="text-5xl lg:text-6xl font-bold text-gray-300">
                Über <span className='text-yellow-hr'>Uns</span>
                </span>
              </div>
              <div class="relative">
                <span class="text-4xl font-bold text-slate-100">
                Über <span className='border border-yellow-hr text-yellow-hr'>Uns</span>
                </span>
              </div>
            </div>
          </div>
          <div className="w-full text-center text-slate-200">
            Wir, die <span className="">HR Personalmanagement GmbH</span>, 
            sind ein führendes Unternehmen im Bereich der <span className="">Arbeitnehmerüberlassung</span> mit Sitz
            in <span className="">Mannheim</span> und einer Betriebsstätte in <span className="">Buchen</span>.
          </div>
          <div className="grid lg:grid-cols-2 gap-5">
            <img src={img_5} className="w-full select-none pointer-events-none rounded-xl shadow-xl" />
            <div className="px-2 sm:px-5 space-y-5 text-lg flex flex-col justify-between">
              <div>
              Unser Fokus liegt darauf, Ihnen qualifizierte Arbeitskräfte zur Verfügung zu stellen und Sie bei der Suche nach dem richtigen Personal zu unterstützen.
              </div>
              <div className="text-lg italic text-yellow-hr font-semibold flex items-center space-x-1.5 lg:ps-5">
                <BadgeCheck size={23} />
                <div>Zuverlässig & Vertrauenswürdig</div>
              </div>
              <div>
                Unser Ziel ist es, Ihnen nicht nur eine Lösung für Ihre Personalbedürfnisse zu bieten, sondern eine langfristige Partnerschaft aufzubauen.
              </div>
              <Link to="/uberuns" className="w-full">
                <Button variant="destructive" className="w-full bg-yellow-hr text-base space-x-1.5">
                  <div>MEHR LESEN</div>
                  <ChevronsRight size={18} />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white w-full py-10 border-t-4 border-dashed border-slate-400">
        <div className="mx-auto w-full px-4 sm:px-0 sm:w-5/6 lg:w-4/6 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3 sm:gap-5">
          {uberUnsItems.map((item, index) => (
            <Card key={index} className="bg-slate-50">
              <CardContent className="p-7">
                <div className="text-xl font-semibold text-slate-900 mb-3">
                  <div className="mb-3">{item.icon}</div>
                  {item.title}
                </div>
                <div className="text-sm text-slate-600">{item.description}</div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
         
      <div className="bg-slate-900 w-full py-10 text-slate-100 border-b-4 border-dashed border-slate-700">
        <div className="mx-auto w-4/6 space-y-8">
          <div className="w-full text-4xl font-semibold text-slate-900">
            <div class="relative text-center mb-5">
              <div class="absolute inset-0 flex items-center justify-center opacity-10">
                <span class="text-5xl lg:text-6xl font-bold text-gray-300">
                Kunden <span className='text-yellow-hr'>&</span> Partner
                </span>
              </div>
              <div class="relative">
                <span class="text-4xl font-bold text-slate-100">
                Kunden <span className='text-yellow-hr'>&</span> Partner
                </span>
              </div>
            </div>
          </div>
                  
          <Carousel className="w-full" plugins={[pluginPartner.current]}>
            <CarouselContent className="px-2">
              {[img_partner_1, img_partner_2, img_partner_3, img_partner_4, img_partner_5, img_partner_6, img_partner_7]
                .map((item, index) => (
                <CarouselItem key={index} className="md:basis-1/2 xl:basis-1/5">
                  {item == img_partner_3 ?
                    <Link to="https://www.giesdl.de/" target="_blank">
                      <img src={item} className="w-full p-5 select-none rounded-lg bg-white" />
                    </Link>
                      :
                    <img src={item} className="w-full p-5 select-none rounded-lg bg-white" />
                  }
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious className="bg-transparent border-none hover:bg-slate-800 hover:text-slate-100" />
            <CarouselNext className="bg-transparent border-none hover:bg-slate-800 hover:text-slate-100" />
          </Carousel>
        </div>
      </div>
    </div>
  )
}

export default Home
