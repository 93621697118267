import React, { useRef } from 'react';
import img_bg1 from '../assets/imgs/banner/bg_new.jpg'; // İlk banner resmi
import img_bg2 from '../assets/imgs/banner/2.jpg'; // İkinci banner resmi
import img_bg3 from '../assets/imgs/banner/3.jpg'; // Üçüncü banner resmi
import img_bg4 from '../assets/imgs/banner/4.jpg'; // Üçüncü banner resmi
import Autoplay from "embla-carousel-autoplay";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"

const defaultBannerItems = [
  {
    img: img_bg1,
    title: <>Ihr Partner in der Metropolregion <span className='text-yellow-hr bg-slate-900/50 px-1'>Rhein-Neckar</span></>,
    description: 'Entdecken Sie, wie wir mit unserer Expertise in Arbeitnehmerüberlassung und Personalvermittlung Ihr Unternehmen unterstützen können.',
    buttons: [
      { text: 'WER WIR SIND', link: '/uberuns', variant: 'outline', className: 'bg-slate-800 border-2 border-slate-500 hover:bg-slate-900 hover:text-blue-200' },
      { text: 'WAS WIR MACHEN', link: '/unserservice', className: 'hover:text-blue-200' },
    ],
    classname: 'pt-20'
  },
  {
    img: img_bg2,
    title: 'Erfahrung trifft Innovation',
    description: 'Profitieren Sie von unserer branchenübergreifenden Erfahrung und einem starken Netzwerk an kompetenten Partnern.',
    buttons: [
        { text: 'WER WIR SIND', link: '/uberuns', variant: 'outline', className: 'bg-slate-800 border-2 border-slate-500 hover:bg-slate-900 hover:text-blue-200' },
        { text: 'WAS WIR MACHEN', link: '/unserservice', className: 'hover:text-blue-200' },
    ],
    classname: 'pt-20'
  },
  {
    img: img_bg4,
    title: 'Umfassende Dienstleistungen für Ihr Wachstum',
    description: 'Wir bieten eine Vielzahl an maßgeschneiderten Lösungen, die speziell auf Ihre Bedürfnisse zugeschnitten sind.',
    buttons: [
        { text: 'WER WIR SIND', link: '/uberuns', variant: 'outline', className: 'bg-slate-800 border-2 border-slate-500 hover:bg-slate-900 hover:text-blue-200' },
        { text: 'WAS WIR MACHEN', link: '/unserservice', className: 'hover:text-blue-200' },
    ],
    classname: 'pt-20'
  },
];

const Banner = ({ containerClassname, contentClassname, bannerItems = defaultBannerItems, breadcrumbListElement = null }) => {
  const pluginBanner = useRef(
    Autoplay({ delay: 5000, stopOnInteraction: false })
  );

  return (
    <>
      <Carousel className={cn("w-full bg-black",
            containerClassname
        )} plugins={[pluginBanner.current]}>
        <CarouselContent className={cn(
            "w-full ml-0 h-[calc(100vh-10rem)]",
            contentClassname
        )}>
            {bannerItems.map((item, index) => (
                <CarouselItem key={index} className="relative p-0">
                <img src={item.img} className="w-full h-full select-none object-cover" />
                <div className={cn(
                    "absolute inset-0 bg-black bg-opacity-70 w-full flex flex-col justify-center text-white",
                    item?.classname
                )}>
                    <div className="w-full px-6 md:px-0 md:w-2/3 mx-auto space-y-5">
                        <h1 className="text-2xl md:text-4xl lg:text-5xl font-bold w-2/3">{item.title}</h1>
                        <p className="text-sm md:text-lg">{item?.description}</p>
                        <div className="space-x-4">
                            {item?.buttons?.map((button, btnIndex) => (
                                <Button key={btnIndex} variant={button.variant} className={button.className}>
                                <a href={button.link}>{button.text}</a>
                                </Button>
                            ))}
                        </div>
                    </div>
                </div>
                </CarouselItem>
            ))}
        </CarouselContent>
        {breadcrumbListElement &&
          <div className="w-full bg-gradient-to-r from-white to-sky-100 border-b p-3 pointer-events-auto">
            <Breadcrumb className="mx-auto w-5/6">
              {breadcrumbListElement}
            </Breadcrumb>
          </div>
        }
      </Carousel>
    </>
  );
}
{/* <CarouselPrevious />
                <CarouselNext /> */}
export default Banner;
