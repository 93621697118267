import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Uberuns from './pages/Uberuns';
import Unserservice from './pages/Unserservice';
import Kontakt from './pages/Kontakt';
import Arbeitnehmerüberlassung from './pages/unserservice/Arbeitnehmerüberlassung';
import Personalvermittlung from './pages/unserservice/Personalvermittlung';
import Dienstleistungen from './pages/unserservice/Dienstleistungen';
import Aviation from './pages/unserservice/Aviation';
import Impressum from './pages/legal/Impressum';
import Datenschutz from './pages/legal/Datenschutz';
import { Toaster } from "@/components/ui/toaster";
import CookieConsentDialog from '@/components/CookieConsentDialog';

function App() {
  const [temporaryConsent, setTemporaryConsent] = useState(false);
  const consent = localStorage.getItem('cookieConsent');
  const isLegalPage = window.location.pathname === "/legal/datenschutz" || window.location.pathname === "/legal/impressum";

  return (
    <Router>
      <div className="flex flex-col min-h-screen" style={{fontFamily: "Poppins, sans-serif", fontSize: "16px"}}>
        {(consent === 'accepted' || temporaryConsent) && <Navbar />}
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/uberuns" element={<Uberuns />} />
            <Route path="/unserservice" element={<Unserservice />} />
            <Route path="/unserservice/arbeitnehmerüberlassung" element={<Arbeitnehmerüberlassung />} />
            <Route path="/unserservice/personalvermittlung" element={<Personalvermittlung />} />
            <Route path="/unserservice/dienstleistungen" element={<Dienstleistungen />} />
            <Route path="/unserservice/aviation" element={<Aviation />} />
            <Route path="/legal/impressum" element={<Impressum />} />
            <Route path="/legal/datenschutz" element={<Datenschutz />} />
            <Route path="/kontakt" element={<Kontakt />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </main>
        {(consent === 'accepted' || temporaryConsent) && <Footer />}
        <div id="google_translate_element" style={{ display: 'none' }}></div>
      </div>
      <Toaster />
      {!isLegalPage && consent !== 'accepted' && !temporaryConsent && (
        <CookieConsentDialog setTemporaryConsent={setTemporaryConsent} />
      )}
    </Router>
  );
}

export default App;
