import React from "react";
import logo from "../assets/imgs/logo.jpg"; // Ensure this path is correct
import { ChevronRight, ChevronsRight, File, MailQuestion, Map, MapPin, MapPinned, Send, ShieldCheck, ShieldQuestion } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "./ui/button";

const Footer = () => {
  const handleDownload = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = url.split('/').pop();
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <footer className="bg-gray-800 mt-auto">
      <div className="bg-white w-full py-10 border-t-4 border-dashed border-slate-400 font-semibold">
        <div className="mx-auto w-5/6 lg:w-4/6 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-7 sm:gap-12">
          <div className="flex flex-col justify-between lg:border-e lg:pe-12">
            <div className="items-center space-x-5 hidden sm:flex notranslate">
              <img src={logo} alt="HR Personal Management" className="h-12 select-none" />
              <div className="text-base text-gray-800">PERSONAL MANAGEMENT</div>
            </div>
            <div>
              <Link to="https://maps.app.goo.gl/pnzLfc3amJsXd7pK6" target="_blank">
                <Button variant="outline" className="flex items-center space-x-1.5 w-full">
                  <MapPinned size={22} className="text-slate-700" />
                  <p className="text-base">Standort</p>
                </Button>
              </Link>
            </div>
          </div>
          <div className="space-y-1 sm:space-y-3 text-sm lg:border-e lg:pe-12">
            <div className="flex items-center space-x-2">
              <Send size={20} />
              <p className="text-xl">Kontaktinformationen</p>
            </div>
            <div className="relative flex items-center">
              <div className="flex-grow border-t border-blue-100"></div>
              <span className="flex-shrink mx-3 text-gray-400 tracking-widest">
                <MailQuestion size={20} />
              </span>
              <div className="flex-grow border-t border-blue-100"></div>
            </div>
            <div className="font-normal text-slate-700 space-y-1">
              <div>Kirchenstr. 12</div>
              <div>68159 Mannheim</div>
              <div>info@hr-personal24.de</div>
            </div>
          </div>
          <div className="space-y-1 sm:space-y-3 text-sm">
            <div className="flex items-center space-x-2">
              <ShieldCheck size={20} />
              <p className="text-xl">Unsere Zertifikate</p>
            </div>
            <div className="relative flex items-center">
              <div className="flex-grow border-t border-blue-100"></div>
              <span className="flex-shrink mx-3 text-gray-400 tracking-widest">
                <ShieldQuestion size={20} />
              </span>
              <div className="flex-grow border-t border-blue-100"></div>
            </div>
            <div className="font-normal text-slate-700 space-y-1">
              <div className="flex items-center space-x-2">
                <ChevronRight size={18} />
                <div onClick={() => handleDownload('/certificates/28.03.2024 PIAQ.pdf')} className="space-x-1 flex items-center hover:text-blue-600 hover:underline cursor-pointer">
                  <File size={18} />
                  <div>PIAQ</div>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <ChevronRight size={18} />
                <div onClick={() => handleDownload('/certificates/Erlaubnis zur Arbeitnehmerüberlassung.pdf')} className="space-x-1 flex items-center hover:text-blue-600 hover:underline cursor-pointer">
                  <File size={18} />
                  <div>Erlaubnis zur Arbeitnehmerüberlassung</div>
                </div>
              </div>
              <div className="flex items-center space-x-2">
                <ChevronRight size={18} />
                <div onClick={() => handleDownload('/certificates/Zertifikat_SCP.pdf')} className="space-x-1 flex items-center hover:text-blue-600 hover:underline cursor-pointer">
                  <File size={18} />
                  <div>Sicherheitsmanagementsystem-Zertifikat: SCP</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container text-white p-4 mx-auto flex justify-between text-slate-400 text-sm">
        <div className="notranslate w-2/3">Copyrights © {new Date().getFullYear()} All Rights Reserved. Powered by <span className="text-sky-50">hr-personal24</span></div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-x-5">
          <Link to="/legal/datenschutz">
            Datenschutz
          </Link>
          <Link to="/legal/impressum">
            Impressum
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
