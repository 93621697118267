import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/imgs/logo.jpg'; // Ensure this path is correct
import { Mail, PhoneCall } from 'lucide-react';
import LanguageSwitcher from './LanguageSwitcher';
import { NavigationMenu, NavigationMenuContent, NavigationMenuIndicator, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger, NavigationMenuViewport } from "@/components/ui/navigation-menu";
import { Button } from './ui/button';
import { Menu, X } from 'lucide-react';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={`fixed top-0 left-0 w-full z-40 transition-all duration-300 ${isScrolled ? 'bg-white' : 'bg-transparent'}`}>
      <div className={`w-full md:w-5/6 mx-auto duration-300 space-y-2`}>
        {!isScrolled && (
          <div className='flex px-4 md:px-0 space-x-4 text-xs md:text-sm text-white opacity-90 xl:container mt-3'>
            <div className='flex items-center space-x-2'>
              <PhoneCall size={18} className='opacity-80' />
              <div>0621 437 576 07</div>
            </div>
            <div className='flex items-center space-x-2'>
              <Mail size={18} className='opacity-80' />
              <div>info@hr-personal24.de</div>
            </div>
          </div>
        )}
        <div className={`grid grid-cols-[1fr_auto] xl:grid-cols-2 items-center rounded-none md:rounded-xl ${isScrolled ? 'bg-white' : 'bg-transparent'} font-semibold`}>
          <Link to="/">
            <div className={`p-4 px-6 lg:px-10 flex items-center notranslate space-x-5 rounded-none md:rounded-s-lg bg-white ${!isScrolled && 'shadow'}`}>
              <img src={logo} alt="HR Personal Management" className="h-8 md:h-12 select-none" />
              <div className="text-xs md:text-base text-gray-800">
                PERSONAL MANAGEMENT
              </div>
            </div>
          </Link>
          <div className={`bg-slate-900 rounded-none md:rounded-e-lg text-white uppercase flex text-sm justify-between transition-none h-full items-center ${isScrolled ? 'bg-white !text-black' : 'shadow'}`}>
            <div className="xl:hidden flex mx-auto px-8 items-center">
              <button onClick={toggleMenu} className={`${isScrolled ? 'text-black' : 'text-white'} focus:outline-none`}>
                {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
            <div className="hidden xl:flex space-x-8 mx-auto">
              <Link to="/" className="transition-all hover:border-b hover:border-blue-400 hover:text-blue-400">
                Home
              </Link>
              <Link to="/uberuns" className="transition-all hover:border-b hover:border-blue-400 hover:text-blue-400">
                Über uns
              </Link>
              <NavigationMenu>
                <NavigationMenuList>
                  <NavigationMenuItem>
                    <NavigationMenuTrigger className={`!bg-transparent h-fit ${isScrolled ? '!text-black' : '!text-white'} !rounded-none p-0 uppercase font-semibold transition-all border-b border-transparent hover:border-blue-400 hover:!text-blue-400`}>
                      <Link to="/unserservice">
                        Unser Service
                      </Link>
                    </NavigationMenuTrigger>
                    <NavigationMenuContent className="z-50">
                      <div className='bg-slate-800 p-2 grid gap-2 mt-2 rounded'>
                        <Link to="/unserservice">
                          <Button variant="secondary" className="w-full text-slate-100 bg-slate-800 font-semibold uppercase border-b rounded-none border-slate-700 hover:bg-slate-900">
                            Unser Service
                          </Button>
                        </Link>
                        <Link to="/unserservice/arbeitnehmerüberlassung">
                          <Button variant="secondary" className="w-full text-slate-100 bg-slate-900 hover:bg-black">
                            Arbeitnehmerüberlassung
                          </Button>
                        </Link>
                        <Link to="/unserservice/personalvermittlung">
                          <Button variant="secondary" className="w-full text-slate-100 bg-slate-900 hover:bg-black">
                            Personalvermittlung
                          </Button>
                        </Link>
                        <Link to="/unserservice/dienstleistungen">
                          <Button variant="secondary" className="w-full text-slate-100 bg-slate-900 hover:bg-black">
                            Dienstleistungen
                          </Button>
                        </Link>
                        <Link to="/unserservice/aviation">
                          <Button variant="secondary" className="w-full text-slate-100 bg-slate-900 hover:bg-black">
                            Aviation
                          </Button>
                        </Link>
                      </div>
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                </NavigationMenuList>
              </NavigationMenu>
              <Link to="/kontakt" className="transition-all hover:border-b hover:border-blue-400 hover:text-blue-400">
                Kontakt
              </Link>
              <LanguageSwitcher classNames={"float-end"} />
            </div>
          </div>
        </div>
        {isMenuOpen && (
          <div className="rounded-lg xl:hidden bg-white text-black p-4 space-y-4">
            <Link to="/" onClick={toggleMenu} className="block">
              Home
            </Link>
            <Link to="/uberuns" onClick={toggleMenu} className="block">
              Über uns
            </Link>
            <Link to="/unserservice" onClick={toggleMenu} className="block">
              Unser Service
            </Link>
            <Link to="/kontakt" onClick={toggleMenu} className="block">
              Kontakt
            </Link>
            <LanguageSwitcher classNames={"block"} />
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
