import React, { useRef } from 'react';
import img_bg from '../../assets/imgs/banner/4.jpg';
import img_1 from '../../assets/imgs/unserservice/1.jpg';
import img_2 from '../../assets/imgs/unserservice/2.jpg';
import Banner from '@/components/Banner';
import {
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import { Separator } from "@/components/ui/separator"
import { Card, CardContent } from "@/components/ui/card";
import img_career_Staplerfahrer from '../../assets/imgs/unserservice/Staplerfahrer.jpeg';
import img_career_Schweiser from '../../assets/imgs/unserservice/Schweißer.jpg';
import img_career_Reinigungsfachkraft from '../../assets/imgs/unserservice/Reinigungsfachkraft.jpg';
import img_career_Maurer from '../../assets/imgs/unserservice/Maurer.jpg';
import img_career_Maschinenbediener from '../../assets/imgs/unserservice/Maschinenbediener.jpg';
import img_career_LKW_Fahrer from '../../assets/imgs/unserservice/LKW Fahrer.jpg';
import img_career_Elektriker from '../../assets/imgs/unserservice/Elektriker.jpg';
import img_career_Disponent from '../../assets/imgs/unserservice/Disponent.jpg';
import img_career_Produktionshelfer from '../../assets/imgs/unserservice/Produktionshelfer.jpg';

import Autoplay from "embla-carousel-autoplay";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { HandHelping } from 'lucide-react';
import { Link } from 'react-router-dom';

const careerItems = [
  {
    img: img_career_Staplerfahrer,
    text: 'Staplerfahrer',
  },
  {
    img: img_career_Schweiser,
    text: 'Schweißer',
  },
  {
    img: img_career_Reinigungsfachkraft,
    text: 'Reinigungsfachkraft',
  },
  {
    img: img_career_Maurer,
    text: 'Maurer',
  },
  {
    img: img_career_Maschinenbediener,
    text: 'Maschinenbediener',
  },
  {
    img: img_career_LKW_Fahrer,
    text: 'LKW Fahrer',
  },
  {
    img: img_career_Elektriker,
    text: 'Elektriker',
  },
  {
    img: img_career_Disponent,
    text: 'Disponent',
  },
  {
    img: img_career_Produktionshelfer,
    text: 'Produktionshelfer',
  },
];

const bannerItems = [
  {
    img: img_bg,
    title: <div className="truncate">Arbeitnehmerüberlassung</div>,
    description: <>
    Unser Unternehmen bietet <span className="">dynamische</span>, <span className="">pulsierende</span> und <span className="">jugendliche</span> Ansätze in der Arbeitnehmerüberlassung. Wir verstehen die Bedürfnisse eines modernen Arbeitsmarktes und reagieren mit <span className="">Flexibilität</span> und <span className="">Schnelligkeit</span> auf die Anforderungen unserer Kunden. Unsere Dienstleistungen sind darauf ausgerichtet, Unternehmen mit <span className="">qualifizierten</span> und <span className="">motivierten</span> Arbeitskräften zu unterstützen, die <span className="">frische Perspektiven</span> und <span className="">Innovationen</span> bringen. Treten Sie in Kontakt mit uns und erleben Sie, wie wir Ihre Personalanforderungen mit <span className="">Lebendigkeit</span> und <span className="">jugendlichem Elan</span> erfüllen.
    </>,  
    classname: 'pt-32 sm:pt-20'
  }
]

const Arbeitnehmerüberlassung = () => {
  const pluginCareer = useRef(
    Autoplay({ delay: 3000, stopOnInteraction: false })
  )
  return (
    <div>
      <Banner
        containerClassname="pointer-events-none select-none"
        contentClassname="h-[62vh] sm:h-[90vh] lg:h-[60vh]"
        bannerItems={bannerItems}
        breadcrumbListElement={
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href="/unserservice">Unser Service</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Arbeitnehmerüberlassung</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        }
      />
      
         
      <div className="bg-slate-100 w-full pb-8 pt-10 text-slate-900 border-b-2">
        <div className="w-full text-4xl font-semibold text-slate-900 pb-2">
          <div class="relative text-center mb-5 truncate">
            <div class="absolute inset-0 flex items-center justify-center opacity-20">
              <span class="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-300">
              Wir bieten folgende Stellen an
              </span>
            </div>
            <div class="relative">
              <span class="text-2xl sm:text-4xl font-bold text-blue-900">
              Wir bieten folgende Stellen an
              </span>
            </div>
          </div>
        </div>
        <div className="mx-auto w-full flex flex-wrap justify-center px-5 md:px-10 gap-5">
          {careerItems.map((item, index) => (
              <div key={index} className="bg-white w-full sm:basis-1/3 md:basis-1/6 shadow-lg rounded-lg transition-all mt-2 hover:m-0 overflow-hidden">
                <img
                  src={item.img}
                  className="w-full h-40 object-cover select-none"
                  alt={item.text}
                />
                <div className="text-center p-2 text-slate-900 font-semibold">
                  {item.text}
                </div>
              </div>
          ))}
        </div>
      </div>

      <div className="bg-white w-full py-10 text-slate-900 border-b-4 space-y-5 border-dashed border-slate-300">
        <div className="w-full text-4xl font-semibold text-slate-900 pb-4">
          <div class="relative text-center mb-5 truncate">
            <div class="absolute inset-0 flex items-center justify-center opacity-20">
              <span class="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-300">
                Arbeitnehmerüberlassung
              </span>
            </div>
            <div class="relative">
              <span class="text-2xl sm:text-4xl font-bold text-blue-900">
                Arbeitnehmerüberlassung
              </span>
            </div>
          </div>
        </div>
        <div className="mx-auto w-5/6 lg:w-4/6 grid grid-cols-2 lg:grid-cols-4 gap-7 gap-y-5 sm:gap-y-10 items-center">
          <img src={img_1} className="w-full col-span-2 rounded select-none pointer-events-none" />
          <div className="space-y-5 text-lg col-span-2">
            <div className='text-slate-800 font-semibold'>
              <div className="text-3xl text-blue-800 font-bold">AÜG</div>
              <div className="italic">ARBEITNEHMERÜBERLASSUNGSGESETZ</div>
            </div>
            <div className='text-base space-y-3'>
              <div>Wir überlassen unser Personal nach dem AÜG und haben hierfür die Zulassung.</div>
              <div>Ob auf Abruf oder zu Spitzenzeiten, unsere Qualifikationen bieten wir dauerhaft an.</div>
              <div>Kurze Reaktionszeiten stellen für uns kein Problem dar!</div>
              <div>Wir verfügen über das Potenzial Ihnen dafür Mitarbeiter zur Verfügung zu stellen.</div>
              <div>Bei uns erhalten Sie alles aus einer Hand.</div>
            </div>
          </div>
          <Separator className="col-span-2 lg:col-span-4" />
          <img src={img_2} className="w-full col-span-2 rounded select-none pointer-events-none" />
          <div className="space-y-5 text-lg col-span-2">
            <div className='text-slate-800 font-semibold'>
              <div className="text-3xl text-blue-800 font-bold">UNSER INNOVATIVER BEWERBUNGSPROZESS</div>
              <div className="italic">WIR ARBEITEN FÜR SIE</div>
            </div>
            <div className='text-base space-y-3'>
              <div>Entgegen dem herkömmlichen Bewerbungsprozess, bei welchem Sie sich als Bewerber in einem Unternehmen bewerben, für das Sie gerne arbeiten möchten, laufen die Bewerbung sowie das Rekruting bei der Arbeitnehmerüberlassung über uns.</div>
              <div>Sie bewerben sich bei uns. Wir führen den Bewerbungsprozess mit Ihnen. Wir stellen Sie ein und Sie schließen den Arbeitsvertrag mit uns. Somit sind Sie fest bei uns eingestellt und Ihr Lohn sowie Ihr Urlaub werden über uns erledigt.</div>
              <div>Im Anschluss daran vermitteln wir Ihnen Projekte in passenden Unternehmen, bei denen Sie Ihren gewünschten Job ausüben können. Nach maximal 18 Monaten im gleichen Unternehmen wechseln Sie in ein anderes Unternehmen. So haben Sie die Möglichkeit verschiedene Kunden und Tätigkeitsbereiche kennenzulernen.</div>
            </div>
          </div>
        </div>
      </div>

      

    </div>
  )
}

export default Arbeitnehmerüberlassung
