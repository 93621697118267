import React, { useRef } from 'react';
import img_bg from '../../assets/imgs/home/4.jpg';
import Banner from '@/components/Banner';
import {
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import img_plane from '../../assets/imgs/unserservice/plane.jpg';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { BaggageClaim, BusFront, Ticket, Plane, Handshake, ConciergeBell, LandPlot, BookCheck } from 'lucide-react';

const bannerItems = [
  {
    img: img_bg,
    title: "Aviation",
    classname: 'pt-20'
  }
];

const tabData = [
  {
    label: "Personenbeförderung",
    icon: <BusFront size={20} />,
    content: (
      <div className='space-y-3'>
        <p className='font-semibold text-3xl'>Personenbeförderung - so einfach wie nie</p>
        <div className='border-2 rounded-xl w-1/3 ms-2'></div>
        <p>Unser Ziel ist es, die Ansprüche unserer Kunden durch eine Vielzahl von Dienstleistungen zu erfüllen. Unser hervorragend geschultes Personal arbeitet hart daran, dass sich die Passagiere während ihres Aufenthalts am Flughafen rundum wohlfühlen. Wir bieten den Passagieren die gleiche hohe Qualität wie bei der Flughafenabfertigung.</p>
        <p>Wir bieten folgende Dienstleistungen bei der Personenbeförderung an:</p>
        <ul className='space-y-1 ps-5'>
          <li><span className='font-semibold'>1. Check-In:</span> Unser freundliches Personal unterstützt Sie beim Abfertigungsprozess, um sicherzustellen, dass Sie schnell und problemlos Ihre Bordkarte erhalten und Ihr Gepäck aufgeben können.</li>
          <li><span className='font-semibold'>2. Gate:</span> Am Gate sorgt unser Team dafür, dass der Einstieg ins Flugzeug reibungslos verläuft. Wir überwachen den gesamten Prozess und stehen Ihnen bei Fragen zur Verfügung.</li>
          <li><span className='font-semibold'>3. Überwachung:</span> Unsere Mitarbeiter überwachen und koordinieren alle Abläufe, um sicherzustellen, dass Ihr Aufenthalt am Flughafen so angenehm wie möglich verläuft.</li>
          <li><span className='font-semibold'>4. Empfangs- und Begleitservice:</span> Unser Empfangs- und Begleitservice bietet individuelle Betreuung für Reisende, die Unterstützung benötigen. Ein Mitarbeiter begleitet Sie vom Check-In bis zum Flugsteig und hilft Ihnen bei allen Schritten, einschließlich der Sicherheitskontrollen.</li>
          <li><span className='font-semibold'>6. Ankunftsservice:</span> Bei Ihrer Ankunft helfen wir Ihnen, schnell und unkompliziert Ihr Gepäck zu finden und den Flughafen zu verlassen. Unser Team steht Ihnen zur Seite, um Ihre Ankunft so angenehm wie möglich zu gestalten.</li>
          <li><span className='font-semibold'>7. Service für alleinreisende Minderjährige:</span> Für alleinreisende Kinder bieten wir einen speziellen Service, der sicherstellt, dass sie während ihrer gesamten Reise gut betreut werden. Wir empfangen die Kinder beim Check-In, überwachen sie während des Fluges und übergeben sie bei der Ankunft sicher an die Abholperson.</li>
        </ul>
        <p>Unser Engagement für exzellenten Service und die Zufriedenheit unserer Passagiere steht stets im Mittelpunkt unseres Handelns. Vertrauen Sie auf unser erfahrenes Team, um Ihre Reise so komfortabel und stressfrei wie möglich zu gestalten.</p>
      </div>
    )
  },
  {
    label: "Fundbüro",
    icon: <BaggageClaim size={20} />,
    content: (
      <div className='space-y-3'>
        <p className='font-semibold text-3xl'>Fundbüro - damit nichts verloren geht</p>
        <div className='border-2 rounded-xl w-1/3 ms-2'></div>
        <p>Unser Fundbüro am Flughafen bietet Ihnen umfassende Dienstleistungen, um verlorenes Gepäck und persönliche Gegenstände so schnell wie möglich zu ihren rechtmäßigen Besitzern zurückzubringen. Unser engagiertes Team setzt alles daran, verlorene Gegenstände effizient und zuverlässig wiederzufinden und Ihnen zurückzugeben.</p>
        <p>Wir bieten folgende Dienstleistungen im Fundbüro an:</p>
        <ul className='space-y-1 ps-5'>
          <li><span className='font-semibold'>1. Passagierservice bei der Gepäckausgabe:</span> Unser freundliches Personal unterstützt Sie direkt bei der Gepäckausgabe und hilft Ihnen, vermisstes Gepäck zu melden.</li>
          <li><span className='font-semibold'>2. Gepäckrückgabe- und Lieferservice:</span> Wir sorgen dafür, dass Ihr Gepäck sicher und schnell zu Ihnen nach Hause oder zu Ihrem Aufenthaltsort geliefert wird. Dafür arbeiten wir eng mit zahlreichen Kurierdiensten zusammen.</li>
          <li><span className='font-semibold'>3. Weltweites Gepäcktracking:</span> Mit unserem weltweiten Gepäckverfolgungsdienst können wir verlorenes Gepäck überall auf der Welt aufspüren und sicherstellen, dass es schnellstmöglich zu Ihnen zurückkehrt.</li>
          <li><span className='font-semibold'>4. Verlustmeldung und Nachverfolgung verlorener Gegenstände:</span> Haben Sie etwas verloren? Unser Team nimmt Ihre Verlustmeldung auf und verfolgt den Status Ihrer verlorenen Gegenstände, bis diese wiedergefunden sind.</li>
          <li><span className='font-semibold'>5. Sammlung und Aufbewahrung von liegengebliebenen Gegenständen:</span> Alle am Flughafen gefundenen Gegenstände werden sicher gesammelt und aufbewahrt, bis sie an ihre Besitzer zurückgegeben werden können.</li>
          <li><span className='font-semibold'>6. Hilfe bei Flugunregelmäßigkeiten:</span> Bei Unregelmäßigkeiten im Flugverkehr stehen wir Ihnen mit Rat und Tat zur Seite und unterstützen Sie dabei, Ihr verlorenes Gepäck so schnell wie möglich wiederzubekommen.</li>
        </ul>
        <p>Unser Ziel ist es, Ihnen einen erstklassigen Service zu bieten und sicherzustellen, dass Ihre Reise trotz eventueller Unannehmlichkeiten so angenehm wie möglich verläuft. Vertrauen Sie auf unser erfahrenes Team, das stets bereit ist, Ihnen zu helfen und Ihre verlorenen Gegenstände sicher und schnell zurückzubringen.</p>
      </div>
    )
  },
  {
    label: "Ticketverkauf",
    icon: <Ticket size={20} />,
    content: (
      <div className='space-y-3'>
        <p className='font-semibold text-3xl'>Ticketverkauf – die Reise geht los</p>
        <div className='border-2 rounded-xl w-1/3 ms-2'></div>
        <p>Manchmal entstehen Situationen, in denen Reisende spontan einen Urlaub planen möchten, ein Meeting verschoben wird oder sie ihren Flug verpasst haben. In solchen Momenten stehen Ihnen unsere Ticketagenten am Flughafen zur Seite. Wir helfen Ihnen schnell und zuverlässig, das passende Ticket zu finden und den günstigsten Preis zu sichern, damit Sie stressfrei weiterreisen können.</p>
        <p>Wir helfen Ihnen gerne – mit Unterstützung der Reservierungssysteme unserer Vertragsfluggesellschaften und von „Amadeus“.</p>
        <p>Wir bieten folgende Dienstleistungen im Ticketverkauf an:</p>
        <ul className='space-y-1 ps-5'>
          <li><span className='font-semibold'>1. Airline-Service-Ticketverkaufsschalter:</span> An unseren Schaltern am Flughafen können Sie direkt mit unseren Ticketagenten sprechen, die Ihnen schnell und unkompliziert bei der Buchung Ihres nächsten Fluges helfen.</li>
          <li><span className='font-semibold'>2. Kundenservice-Hotline:</span> Unsere Hotline ist jederzeit erreichbar, um Ihnen bei Fragen und Buchungen weiterzuhelfen. Egal, ob Sie ein neues Ticket benötigen oder Unterstützung bei einer bestehenden Buchung brauchen – wir sind für Sie da.</li>
          <li><span className='font-semibold'>3. Zentralisierter Ticketverkauf:</span> Durch unser zentrales System können wir Ihnen eine breite Auswahl an Flügen und Fluggesellschaften anbieten. Unsere Agenten finden für Sie die besten Verbindungen und Preise.</li>
          <li><span className='font-semibold'>4. Unregelmäßigkeiten-Management:</span> Bei Flugunregelmäßigkeiten, wie Verspätungen oder Ausfällen, unterstützen wir Sie nicht nur bei der Umbuchung, sondern kümmern uns auch um Hotelunterkünfte und Transport, damit Ihre Reise so angenehm wie möglich verläuft.</li>
        </ul>
        <p>Unser Ziel ist es, Ihnen jederzeit einen hervorragenden Service zu bieten und dafür zu sorgen, dass Sie stressfrei und komfortabel reisen können. Vertrauen Sie auf unser erfahrenes Team, das stets bereit ist, Ihnen bei all Ihren Anliegen rund um den Ticketverkauf zu helfen.</p>
      </div>
    )
  },
  {
    label: "Flughafenoperationen",
    icon: <Handshake size={20} />,
    content: (
      <div className='space-y-3'>
        <p className='font-semibold text-3xl'>Flughafenoperationen – es läuft wie geschmiert</p>
        <div className='border-2 rounded-xl w-1/3 ms-2'></div>
        <p>Mit über 30 Jahren Erfahrung bieten wir umfangreiche Dienstleistungen im Bereich der Flughafenoperationen an. Unsere Expertise umfasst eine Vielzahl von Tätigkeiten, die für einen reibungslosen Ablauf am Flughafen unerlässlich sind.</p>
        <p>Wir bieten folgende Dienstleistungen im Bereich der Operations an:</p>
        <ul className='space-y-1 ps-5'>
          <li><span className='font-semibold'>1. Rampenaufsicht und Koordination:</span> Unsere erfahrenen Mitarbeiter überwachen und koordinieren alle Aktivitäten auf den Rollfeldern, um einen sicheren und effizienten Betrieb zu gewährleisten.</li>
          <li><span className='font-semibold'>2. Assistenz beim Pushback und Walkout:</span> Wir unterstützen die Flugzeugabfertigung, einschließlich des Rückwärtsziehens vom Gate und der Abfertigung auf dem Rollfeld.</li>
          <li><span className='font-semibold'>3. Zentrale Lastenplanung und Koordination (CLC):</span> Durch unser zentrales System für die Lastenplanung und -koordination gewährleisten wir eine optimale Beladung der Flugzeuge gemäß den Vorgaben.</li>
          <li><span className='font-semibold'>4. Flugdispatching (OCC):</span> Unser Operations Control Center (OCC) überwacht und koordiniert alle flugbetrieblichen Abläufe, um eine pünktliche und effiziente Abwicklung der Flüge sicherzustellen.</li>
          <li><span className='font-semibold'>5. Verwaltung von Ladegeräten:</span> Wir kümmern uns um die Verwaltung und Wartung der Ladegeräte für die Flugzeugbeladung, um einen reibungslosen Betrieb zu gewährleisten.</li>
          <li><span className='font-semibold'>6. Annahme und Dokumentation von Fracht:</span> Unser Team steht für die Annahme und Dokumentation von Frachtgütern bereit, um einen sicheren Transport und eine korrekte Abwicklung zu gewährleisten.</li>
        </ul>
        <p>Mit unserem Engagement für Qualität und Zuverlässigkeit tragen wir dazu bei, dass der Flugbetrieb am Flughafen effizient und sicher abläuft. Verlassen Sie sich auf unsere langjährige Erfahrung und Expertise, um Ihre Anforderungen im Bereich der Flughafenoperationen bestmöglich zu erfüllen.</p>
      </div>
    )
  },
  {
    label: "Consulting",
    icon: <LandPlot size={20} />,
    content: (
      <div className='space-y-3'>
        <p className='font-semibold text-3xl'>Consulting – die Experten rund um das Thema Flughafen</p>
        <div className='border-2 rounded-xl w-1/3 ms-2'></div>
        <p>Durch unsere 30-jährige Erfahrung und unsere hohe Qualität haben wir einen klaren Vorsprung im Bereich der Beratung am Flughafen. Unser Expertenteam bietet Ihnen umfassende Dienstleistungen, die speziell auf die Bedürfnisse der Luftfahrtindustrie zugeschnitten sind.</p>
        <p>Wir bieten folgende Dienstleistungen in der Beratung an:</p>
        <ul className='space-y-1 ps-5'>
          <li><span className='font-semibold'>1. Airline Station Management:</span> Wir unterstützen Fluggesellschaften bei der effizienten Verwaltung ihrer Stationen am Flughafen, um einen reibungslosen Betrieb zu gewährleisten.</li>
          <li><span className='font-semibold'>2. Airline Profitability:</span> Durch unsere Beratungsdienste helfen wir Fluggesellschaften, ihre Rentabilität zu maximieren und operative Effizienz zu steigern.</li>
          <li><span className='font-semibold'>3. Invoice Control:</span> Wir übernehmen die Kontrolle und Verwaltung von Rechnungen, um sicherzustellen, dass alle Zahlungen korrekt und rechtzeitig abgewickelt werden.</li>
          <li><span className='font-semibold'>4. Daten und Berichterstattung:</span> Unsere Experten erstellen detaillierte Datenanalysen und Berichte, um fundierte Entscheidungen zu ermöglichen und Geschäftsprozesse zu optimieren.</li>
          <li><span className='font-semibold'>5. Flughafenanlagenmanagement:</span> Wir bieten Lösungen für das effektive Management von Flughafeninfrastrukturen, um den Betrieb zu optimieren und die Sicherheit zu gewährleisten.</li>
          <li><span className='font-semibold'>6. Sicherheit und Qualität:</span> Wir legen besonderen Wert auf Sicherheit und Qualität in allen Bereichen unserer Beratungsdienste, um die höchsten Standards zu erfüllen und zu übertreffen.</li>
        </ul>
        <p>Vertrauen Sie auf unser Fachwissen und unsere langjährige Erfahrung, um Ihre Herausforderungen in der Luftfahrtindustrie erfolgreich zu bewältigen. Wir sind Ihr verlässlicher Partner für eine nachhaltige und effiziente Entwicklung am Flughafen.</p>
      </div>
    )
  },
  {
    label: "Zertifizierung und Schulung",
    icon: <BookCheck size={20} />,
    content: (
      <div className='space-y-3'>
        <p className='font-semibold text-3xl'>Zertifizierung und Schulung</p>
        <div className='border-2 rounded-xl w-1/3 ms-2'></div>
        <p>Durch unsere 30-jährige Erfahrung und unsere hohe Qualität sind wir führend in der Zertifizierung und Schulung am Flughafen. Unsere Dienstleistungen umfassen:</p>
        <ul className="space-y-1 list-disc list-inside ps-5">
          <li>Loader, Driver and Aircraft Handler</li>
          <li>Pushback</li>
          <li>Headloader</li>
          <li>Ramp Agent / Turnaround Coordinator</li>
          <li>Loadcontrol</li>
          <li>Headset Operation / Walkout</li>
          <li>GSE (Ground Service Equipment)</li>
          <li>Gefahrgut – Kat H, I, L, M</li>
          <li>Umgang mit radioaktivem Material</li>
          <li>Rampensicherheit, Humanfaktor</li>
          <li>Feuerwehrschutz</li>
          <li>Soft Skills</li>
          <li>Qualitätsmanagement</li>
          <li>Datenschutz</li>
          <li>SMS (Safety Management System)</li>
        </ul>
        <p>Verlassen Sie sich auf unsere Expertise, um Ihre Mitarbeiter optimal zu schulen und zu zertifizieren, damit Sie den Betrieb am Flughafen effizient und sicher gestalten können.</p>
      </div>
    )
  }
];

const Aviation = () => {
  return (
    <div>
      <Banner
        containerClassname="pointer-events-none select-none"
        contentClassname="h-[40vh]"
        bannerItems={bannerItems}
        breadcrumbListElement={
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbLink href="/unserservice">Unser Service</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Aviation</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        }
      />

      <div className="bg-white w-full pb-8 pt-10 text-slate-900 border-b-2">
        <div className="w-full text-4xl font-semibold text-slate-900 pb-4">
          <div className="relative text-center mb-5">
            <div className="absolute inset-0 flex items-center justify-center opacity-20">
              <span className="text-5xl lg:text-6xl font-bold text-gray-300">
                Aviation.
              </span>
            </div>
            <div className="relative">
              <span className="text-4xl font-bold text-blue-900">
                Aviation
              </span>
            </div>
          </div>
        </div>
        <div className="mx-auto w-5/6 lg:w-4/6 grid grid-cols-1 lg:grid-cols-10 gap-6">
          <div className='block lg:hidden row-span-2 transition-all p-0 hover:p-1'>
            <img src={img_plane} alt="Aviation" className='w-[max-content] rounded-xl shadow-xl' />
          </div>
          <div className='lg:col-span-6'>
            Wir sind ein professionelles Abfertigungsunternehmen für die
            Luftfahrtindustrie und legen höchsten Wert auf Qualität und
            Disziplin. Unser Team besteht aus hochqualifizierten Fachkräften,
            die über mehr als 30 Jahre gebündelte Erfahrung in der Branche
            verfügen. Diese langjährige Expertise erlaubt es uns, stets auf dem
            neuesten Stand der Technik und der besten Praktiken zu bleiben, um
            unseren Kunden erstklassige Dienstleistungen zu bieten.
          </div>
          <div className='hidden lg:block col-span-4 row-span-2 transition-all p-0 hover:p-1'>
            <img src={img_plane} alt="Aviation" className='w-[max-content] rounded-xl shadow-xl' />
          </div>
          <div className='lg:col-span-10 xl:col-span-6'>
            Durch konsequente Schulungen und Weiterbildungen stellen wir sicher,
            dass unser Personal stets bestens vorbereitet ist, um den hohen
            Anforderungen der gerecht zu werden. Unsere Mitarbeiter
            zeichnen sich durch fundiertes Wissen, hohe Professionalität und
            eine ausgeprägte Serviceorientierung aus.
          </div>
          <div className='lg:col-span-10'>
            Wir bieten wettbewerbsfähige Preise und kombinieren diese mit einem
            kundenorientierten Ansatz. Dabei stehen Sicherheit und Effizienz
            immer im Mittelpunkt unseres Handelns. Unser Ziel ist es, nicht nur
            die Erwartungen unserer Kunden zu erfüllen, sondern sie zu
            übertreffen, indem wir maßgeschneiderte Lösungen und einen
            exzellenten Service bieten. So tragen wir dazu bei, dass der gesamte
            Ablauf reibungslos und zuverlässig verläuft. Vertrauen Sie auf
            unsere Erfahrung und unser Engagement, um Ihre Bedürfnisse in der
            Luftfahrtindustrie bestmöglich zu erfüllen.
          </div>
        </div>
      </div>

      <div className="bg-gradient-to-br from-cyan-100 via-slate-100 to-white w-full py-10 text-slate-100">
        <div className="mx-auto w-full px-2 sm:px-3 sm:px-0 sm:w-5/6 lg:w-4/6">
          <Tabs defaultValue="Personenbeförderung" className="w-full grid grid-cols-1 lg:grid-cols-12 gap-3 min-h-[750px]" orientation='vertical'>
            <TabsList className="flex-col h-fit lg:col-span-3 bg-white space-y-2 p-2 border">
              {tabData.map((tab) => (
                <TabsTrigger key={tab.label} value={tab.label} className="w-full me-auto justify-start bg-slate-100 text-slate-800 space-x-3 data-[state=active]:bg-slate-900 data-[state=active]:text-white">
                  {tab.icon}
                  <div>{tab.label}</div>
                </TabsTrigger>
              ))}
            </TabsList>
            <div className="lg:col-span-9 bg-white px-2 pt-0 sm:px-4 py-1 rounded-lg text-slate-800 border">
              {tabData.map((tab) => (
                <TabsContent key={tab.label} value={tab.label}>
                  {tab.content}
                </TabsContent>
              ))}
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

export default Aviation;
