import React, { useRef } from 'react';
import img_bg from '../assets/imgs/banner/2.jpg';
import { Card, CardContent } from "@/components/ui/card";
import Banner from '@/components/Banner';
import { Link } from 'react-router-dom';
import {
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb"
import img_1 from '../assets/imgs/home/1.jpg';
import img_2 from '../assets/imgs/home/2.jpg';
import img_3 from '../assets/imgs/home/3.jpg';
import img_4 from '../assets/imgs/home/4.jpg';
import Autoplay from "embla-carousel-autoplay";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import {  ChevronsUp, GripHorizontal, HandHelping, Telescope, ShieldCheck } from 'lucide-react';


const carouselCareerItems = [
  {
    img: img_1,
    title: 'Arbeitnehmerüberlassung',
    description: 'Wir sind Ihr kompetenter Partner für Ihren Personalbedarf.',
    icon: <HandHelping size={20} />,
    url: '/unserservice/arbeitnehmerüberlassung'
  },
  {
    img: img_2,
    title: 'Personalvermittlung',
    description: 'Qualifiziertes Personal finden Sie in kürzester Zeit durch unser Engagement.',
    icon: <ChevronsUp size={20} />,
    url: '/unserservice/personalvermittlung'
  },
  {
    img: img_3,
    title: 'Dienstleistungen',
    description: 'Wir bieten umfassende Dienstleistungen von der Produktion bis zur Schweißtechnik an',
    icon: <GripHorizontal size={20} />,
    url: '/unserservice/dienstleistungen'
  },
  {
    img: img_4,
    title: 'Aviation',
    description: 'Mit unseren umfassenden Luftfahrtdiensten setzen wir neue Standards in Betrieb und Wartung für höchste Sicherheit...',
    icon: <ShieldCheck size={20} />,
    url: '/unserservice/aviation'
  }
];


const bannerItems = [
  {
    img: img_bg,
    title: <>Unser <span className='border-b border-yellow-hr text-yellow-hr'>Service</span></>,
    description: <>
      <span className="">Professionell</span>, authentisch und seriös. 
      Suchen Sie eine <span className="">neue Herausforderung</span> oder möchten Sie einfach den <span className="">Arbeitsmarkt-Dschungel</span> durchblicken? 
      Der Arbeitsmarkt ist oft nicht leicht zu durchblicken. Wir unterstützen Sie dabei.
    </>,
    classname: 'pt-28 sm:pt-20'
  }
]

const Unserservice = () => {
  const pluginCareer = useRef(
    Autoplay({ delay: 5000, stopOnInteraction: false })
  );
  return (
    <div>
      <Banner
        containerClassname="pointer-events-none select-none"
        contentClassname="h-[44vh] sm:h-[60vh]"
        bannerItems={bannerItems}
        breadcrumbListElement={
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Home</BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            <BreadcrumbItem>
              <BreadcrumbPage>Unser Service</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        }
      />
      

      <div className="bg-white w-full py-10">
        <div className="mx-auto w-5/6 lg:w-4/6 space-y-7">
          <div className="w-full text-4xl font-semibold text-slate-900">
            <div class="relative text-center mb-5">
              <div class="absolute inset-0 flex items-center justify-center opacity-20">
                <span class="text-5xl lg:text-6xl font-bold text-gray-300">Unser Service</span>
              </div>
              <div class="relative">
                <span class="text-4xl font-bold text-blue-900">Unser Service</span>
              </div>
            </div>
          </div>
          <div className="w-full text-center text-slate-600">
            Als familiengeführtes Personaldienstleistungsunternehmen mit Sitz in Mannheim sind wir spezialisiert auf die Bereitstellung von Arbeitskräften in der wirtschaftsstarken Metropolregion Rhein-Neckar sowie bundesweit. Zu unseren Dienstleistungen gehören Schweißtechniken, Unterstützung von Produktionsprozessen, Bauendreinigung, Unterhaltsreinigung, Glasreinigung sowie Winterdienste und Hausmeisterservices. Wir sind hier, um maßgeschneiderte Lösungen für die Bedürfnisse jedes Kunden zu entwickeln. Kontaktieren Sie uns, und wir planen gemeinsam den Service, der am besten zu Ihren Anforderungen passt.
          </div>

        </div>
      </div>
      
                  
      <div className="mx-auto w-full flex flex-wrap justify-center px-5 md:px-10 gap-5 pb-10">
              {carouselCareerItems.map((item, index) => (
              <Card key={index} className="bg-slate-100 w-full lg:basis-1/3 2xl:basis-1/5 shadow-lg rounded-lg transition-all overflow-hidden">
                      <Link to={item.url}>
                        <CardContent className="aspect-square items-center rounded-lg justify-center bg-slate-100 p-0 pb-0.5">
                          <div className="w-full rounded-t-lg bg-black">
                            <img src={item.img} className="w-full rounded-t-lg select-none opacity-80" />
                          </div>
                          <div className='text-center p-3 px-5 m-4 rounded bg-white border relative z-20 h-fit space-y-2' style={{ marginTop: '-2rem' }}>
                            <div className="text-lg truncate font-semibold">
                              {item.title}
                            </div>
                            <div className="relative flex items-center">
                              <div className="flex-grow border-t border-blue-100"></div>
                              <span className="flex-shrink mx-3 text-gray-400 tracking-widest">
                                {item.icon}
                              </span>
                              <div className="flex-grow border-t border-blue-100"></div>
                            </div>
                            <div className="w-full text-center text-sm text-slate-600">
                              {item.description}
                            </div>
                          </div>
                        </CardContent>
                        </Link>
                </Card>
              ))}
          </div>
    </div>
  )
}

export default Unserservice
